import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Col, FlexboxGrid, Form, SelectPicker, Stack, useToaster } from "rsuite";

import { notEmpty } from '../services/utils';

import { useApiFiltro } from "../hooks/useApiFiltro";

import Confirm from "./Confirm";
import DataModal from "./DataModal";
import { showMessage } from "./ShowMessage";
import Textarea from "./Textarea";

const SelectSalvaFiltro = forwardRef(({
    idTela,
    validateParametros,
    getParametros,
    onSelectFilter,
    readOnly
}, ref) => {
    const apiFiltro = useApiFiltro()

    const stackContainerRef = useRef()

    const [value, setValue] = useState()

    const [filtro, setFiltro] = useState()
    const [filtros, setFiltros] = useState()

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openConfirmSalvar, setOpenConfirmSalvar] = useState(false);
    const [openConfirmExcluir, setOpenConfirmExcluir] = useState(false);
    const [openSaveFiltro, setOpenSaveFiltro] = useState(false)

    const [newWidth, setNewWidth] = useState()

    useEffect(() => {
        // Função para obter a largura do Form.Group
        const getNewWidth = () => {
            if (stackContainerRef.current) {
                const formGroupWidth = stackContainerRef.current.getBoundingClientRect().width - 1;
                const adjustedWidth = Math.ceil(formGroupWidth) - 1;
                setNewWidth(adjustedWidth)
            }
        }

        getNewWidth()

        // Adicionar ouvinte de evento de redimensionamento ao montar o componente
        window.addEventListener('resize', getNewWidth);

        // Remover o ouvinte de evento ao desmontar o componente
        return () => {
            window.removeEventListener('resize', getNewWidth);
        };

    }, [stackContainerRef])

    const getFiltros = async () => {
        setLoading(true)
        try {
            const data = await apiFiltro.getFiltros(idTela)
            const auxData = data.rows.map(item => ({ label: `${item.nomeFiltro} - ${item.descricaoFiltro}`, value: item.idFiltro, nome: item.nomeFiltro }))
            setFiltros(auxData)
        } catch (error) {
            setFiltros()
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => getFiltros())();
        // eslint-disable-next-line
    }, [])

    const handleClickCancel = () => {
        setOpenSaveFiltro(false)
        setWaiting(false)
    }

    const handleClickSalvar = async () => {
        setOpenConfirmSalvar(false)
        setWaiting(true)
        try {
            await apiFiltro.saveFiltro(filtro)
            showSucess()
            handleClickCancel()
            await getFiltros()
        } catch (error) {
            showError(error)
        }
    }

    const handleClickExcluir = async () => {
        setOpenConfirmExcluir(false)
        setWaiting(true)
        try {
            await apiFiltro.deleteFiltro(value)
            setValue(null)
            showSucess(true)
            handleClickCancel()
            await getFiltros()
        } catch (error) {
            showError(error)
        }
    }

    const handleClickSalvaFiltro = async () => {
        const validate = validateParametros ? (await validateParametros()) : true
        if (validate) setOpenSaveFiltro(true)
    }

    const handleOpenDataModal = async () => {
        const parametrosFiltro = getParametros ? await getParametros() : null
        setFiltro({ ...filtro, idTela, nomeFiltro: '', descricaoFiltro: '', parametrosFiltro })
    }

    const handleChange = async value => {
        try {
            setValue(value)
            if (!value) return

            const data = await apiFiltro.getFiltro(value)
            if (data && onSelectFilter) onSelectFilter(data.parametrosFiltro)
        } catch (error) {
            showError(error)
        }
    }

    const toaster = useToaster();
    const showSucess = excluir => showMessage({ toaster, successMessage: `${excluir ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const clear = () => setValue(null)

    useImperativeHandle(ref, () => ({
        clear
    }));

    const [showErrorMessage, setShowErrorMessage] = useState({
        descricao: false
    })

    const formValidation = {
        nomeFiltro: {
            validation: (input) => notEmpty(input.value),
            message: 'Nome do filtro é obrigatório',
        },
        descricaoFiltro: {
            validation: (input) => notEmpty(input.value),
            message: 'Descrição do filtro é obrigatório',
        }
    }

    return (
        <>
            <Stack spacing={10} ref={stackContainerRef}>
                <SelectPicker
                    readOnly={readOnly}
                    style={{ width: newWidth }}
                    data={filtros || []}
                    value={value}
                    placeholder="Selecione um filtro salvo"
                    loading={loading}
                    onChange={handleChange}
                    renderValue={(_, items) => items.nome}
                />
                {value
                    ? <Button appearance="ghost" color="red" onClick={() => setOpenConfirmExcluir(true)}>Excluir filtro</Button>
                    : <Button appearance="ghost" color="blue" onClick={handleClickSalvaFiltro}>Salvar filtro</Button>
                }
            </Stack>

            <DataModal
                title={'Salvar Filtro'}
                open={openSaveFiltro}
                onOpen={handleOpenDataModal}
                waiting={waiting}
                onClickSubmitButton={() => setOpenConfirmSalvar(true)}
                onClickCancelButton={handleClickCancel}
                checkFormValidation={true}
                formValidation={formValidation}
                setShowErrorMessage={setShowErrorMessage}
                sizeModal={'sm'}
                bodyHeight={230}
            >
                <FlexboxGrid className="flex-grid-form" align="top">
                    <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                        <Form.Group controlId="nomeFiltro">
                            <Form.ControlLabel>Nome do FIltro</Form.ControlLabel>
                            <Form.Control
                                name="nomeFiltro"
                                autoFocus
                                value={filtro ? filtro.nomeFiltro : ''}
                                onChange={value => setFiltro({ ...filtro, nomeFiltro: value.toUpperCase() })}
                            />
                            <Form.ErrorMessage show={showErrorMessage.nomeFiltro}>{formValidation.nomeFiltro.message}</Form.ErrorMessage>
                            <Form.HelpText>Obrigatório</Form.HelpText>
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                        <Form.Group controlId="descricaoFiltro">
                            <Form.ControlLabel>Descrição do FIltro</Form.ControlLabel>
                            <Form.Control
                                name="descricaoFiltro"
                                accepter={Textarea}
                                rows={3}
                                value={filtro ? filtro.descricaoFiltro : ''}
                                onChange={value => setFiltro({ ...filtro, descricaoFiltro: value.toUpperCase() })}
                            />
                            <Form.ErrorMessage show={showErrorMessage.descricaoFiltro}>{formValidation.descricaoFiltro.message}</Form.ErrorMessage>
                            <Form.HelpText>Obrigatório</Form.HelpText>
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </DataModal>

            <Confirm
                open={openConfirmSalvar}
                message={`Confirma a gravação do filtro?`}
                onClickNoButton={() => setOpenConfirmSalvar(false)}
                onClickYesButton={handleClickSalvar}
            />

            <Confirm
                open={openConfirmExcluir}
                message={`Confirma a exclusão do filtro selecionado?`}
                onClickNoButton={() => setOpenConfirmExcluir(false)}
                onClickYesButton={handleClickExcluir}
            />
        </>
    )
})

export default SelectSalvaFiltro