import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { Checkbox, IconButton, Loader, Modal, Stack, Table, Tooltip, Whisper, useToaster } from "rsuite";

import SearchIcon from '@rsuite/icons/Search';

import Confirm from "../../components/Confirm";
import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import { showMessage } from "../../components/ShowMessage";

import { EmpresaContext } from "../../contexts/empresaContext";

import { useApiLeadsEnvia } from "../../hooks/useApiLeadsEnvia";

import { dateFromString, formataCNPJ } from "../../services/utils";

import CnpjConsultar from "../CnpjConsultar";
import CustomHeader from "./CustomHeader";
import './style.css';

const { Cell } = Table;

// Componente para renderizar a linha expandida
const RowCnae = ({ rowData }) => {
    return (
        <div className="leads-envia-cell-cnae-data" >
            CNAE: {rowData['codigo_cnae']} - {rowData['descricao_cnae']}
        </div>
    )
}

const defaultFilterFields = {
    razao_social: '',
    municipio: '',
    uf: '',
    codigo_cnae: '',
    descricao_cnae: '',
    data_abertura_inicial: null,
    data_abertura_final: null,
    capital_social_inicial: '',
    capital_social_final: ''
}

const defaultShowOptions = {
    exibir_marcado: true,
    exibir_nao_marcado: true,
    exibir_enviado: false
}

const LeadsEnvia = () => {
    const location = useLocation();
    const idLeads = location.state && location.state.idLeads;

    if (!idLeads) return <Navigate to='/' replace={true} />

    return <LeadsEnviaCompleto idLeads={idLeads} />
}

const tooltipViewCnpj = (
    <Tooltip>
        Visualiza os dados da empresa.
    </Tooltip>
)

const LeadsEnviaCompleto = ({ idLeads }) => {
    const { empresaId, permiteApiPropriaLeads } = useContext(EmpresaContext)

    const apiLeadsEnvia = useApiLeadsEnvia()

    const [leads, setLeads] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [filterParams, setFilterParams] = useState(defaultFilterFields)
    const [filterFields, setFilterFields] = useState(defaultFilterFields)
    const [showOptions, setShowOptions] = useState(defaultShowOptions)

    const [cnpjView, setCnpjView] = useState()
    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openConfirm, setOpenConfirm] = useState(false)

    const getLeads = async () => {
        setLoading(true)
        if (!empresaId || !idLeads || !dataTableParams) {
            setLeads({})
        } else {
            try {
                const data = await apiLeadsEnvia.getLeads({ ...dataTableParams, ...filterParams, ...showOptions, idEmpresa: empresaId, idLeads })
                setLeads({
                    data: data.rows,
                    total: data.total,
                    totalSelecionado: data.totalSelecionado,
                    filtered: parseInt(data.filtered) || 0,
                    filterGeral: parseInt(data.filterGeral) || 0,
                    filterSelecionado: parseInt(data.filterSelecionado) || 0,
                    filterJaEnviado: parseInt(data.filterJaEnviado) || 0,
                })
            } catch (error) {
                setLeads({});
                showError(error)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getLeads())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams, filterParams, showOptions])

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            await apiLeadsEnvia.sendLead(idLeads, empresaId)
            showSucess()
            await getLeads()
            setWaiting(false)

        } catch (error) {
            showError(error)
        }
    }

    const handleClickApplyFilter = () => {
        setFilterParams(filterFields)
    }

    const handleClickClearFilter = () => {
        setFilterParams(defaultFilterFields)
        setFilterFields(defaultFilterFields)
    }

    const handleClickSendLeads = () => {
        if (leads.totalSelecionado && leads.totalSelecionado > 0) {
            setOpenConfirm(true)
        } else {
            showError({ response: { data: { message: 'Não há leads selecionados.' } } })
        }
    }

    const handleChangeCheckAll = async (_, checked) => {
        setLoading(true)
        apiLeadsEnvia.checkAllLead({
            ...filterParams,
            idEmpresa: empresaId,
            idLeads,
            checked
        })
            .catch(error => {
                showError(error)
                setLoading(false)
            })
            .finally(async () => {
                await getLeads()
            })
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `Envio concluído com sucesso` })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    // Coluna para checar o lead
    // Componente declarado aqui para poder utilizar a função getLeads
    const CellCheckBox = ({ rowData, dataKey, ...props }) => {
        const [itemOnCheck, setItemOnCheck] = useState()

        const handleChange = async () => {
            try {
                setItemOnCheck(rowData.id)
                const result = await apiLeadsEnvia.checkLead(rowData.id)
                if (result.status === 200) await getLeads()
                setItemOnCheck(null)
            } catch (error) {
                setItemOnCheck(null)
            }
        }

        return (
            <Cell {...props} style={{ padding: 0 }}>
                {rowData['dataEnvio']
                    ? ''
                    : <div style={{ lineHeight: '46px', textAlign: 'center', width: '100%' }}>
                        {itemOnCheck && itemOnCheck === rowData.id
                            ? <Loader size="xs" />
                            : <Checkbox
                                inline
                                onChange={handleChange}
                                checked={rowData[dataKey]}
                            />
                        }
                    </div>
                }
            </Cell>
        )
    }

    const checkHeaderCell = () => {
        let checked = false
        let indeterminate = false

        if (leads.filterSelecionado === (leads.filterGeral - leads.filterJaEnviado)) {
            checked = true
        } else if (leads.filterSelecionado === 0) {
            checked = false
        } else if (leads.filterSelecionado > 0 && leads.filterSelecionado < (leads.filterGeral - leads.filterJaEnviado)) {
            indeterminate = true;
        }

        return (
            <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleChangeCheckAll}
            />
        )
    }

    const dataTableColumns = {
        columns: [
            {
                headerCell: checkHeaderCell,
                headerStyle: { padding: 0 },
                dataKey: 'selecionado',
                width: 40,
                sortable: false,
                customCell: CellCheckBox
            }, {
                dataKey: 'cnpj',
                headerCell: 'CNPJ',
                width: 170,
                sortable: true,
                customCellContent: (rowData, col) => (
                    <div className="leads-envia-cell-cnpj">
                        {formataCNPJ(rowData[col.dataKey])}
                        <Whisper placement="top" controlId="control-id-view-cnpj" trigger="hover" speaker={tooltipViewCnpj}>
                            <IconButton icon={<SearchIcon />} appearance="ghost" onClick={() => setCnpjView(rowData[col.dataKey])} />
                        </Whisper>

                    </div>)
            }, {
                dataKey: 'razao_social',
                headerCell: 'Razão Social',
                flexGrow: 2,
                minWidth: 250,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'capital_social',
                headerCell: 'Capital Social',
                minWidth: 50,
                flexGrow: 1,
                align: 'right',
                sortable: true,
                customCellContent: (rowData, col) => parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')
            }, {
                dataKey: 'data_abertura',
                headerCell: 'Data Abertura',
                width: 120,
                sortable: true,
                align: 'center',
                customCellContent: (rowData, col) => {
                    if (!rowData[col.dataKey]) return ''
                    return dateFromString(rowData[col.dataKey]).toLocaleDateString('pt-BR')
                }
            }, {
                dataKey: 'uf',
                headerCell: 'UF',
                width: 60,
                sortable: true
            }, {
                dataKey: 'municipio',
                headerCell: 'Município',
                flexGrow: 1,
                minWidth: 150,
                sortable: true,
                fullText: true
            }
        ]
    }

    if (showOptions.exibir_enviado) {
        dataTableColumns.columns.push({
            dataKey: 'dataEnvio',
            headerCell: 'Enviado em',
            width: 150,
            sortable: true,
            fullText: true,
            customCellContent: (rowData, col) => {
                if (!rowData[col.dataKey]) return ''
                const date = new Date(rowData[col.dataKey])
                return date.toLocaleDateString('pt-BR') + ' ' + date.toLocaleTimeString('pt-BR', { timeStyle: 'short' })
            }
        })
    }

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>Seleção de Leads para envio ou busca por API</div>

            {waiting
                ? <Loading text={'Enviando Leads ...'} />
                : <>
                    <DataTable
                        data={leads}
                        dataTableColumns={dataTableColumns}
                        placeholderSearch='id, razão social, cnpj, município, cnae'
                        loading={loading}
                        setLoading={setLoading}
                        onChangeParams={params => setDataTableParams(params)}
                        CustomHeader={<CustomHeader
                            totalSelecionado={leads.totalSelecionado}
                            filterGeral={leads.filterGeral}
                            filterJaEnviado={leads.filterJaEnviado}
                            filterSelecionado={leads.filterSelecionado}
                            showOptions={showOptions}
                            setShowOptions={setShowOptions}
                            filterFields={filterFields}
                            setFilterFields={setFilterFields}
                            onClickApplyFilter={handleClickApplyFilter}
                            onClickClearFilter={handleClickClearFilter}
                            onClickSendLeads={handleClickSendLeads}
                            showButtonSendLeads={permiteApiPropriaLeads}
                        />}
                        defaultItemsPerPage={5}
                        rowKey={'id'}
                        expandedRowKeys={leads.data && leads.data.map(c => c.id)}
                        renderRowExpanded={rowData => <RowCnae rowData={rowData} />}
                        rowExpandedHeight={44}
                        rowClassName={'leads-envia-table-row'}
                    />

                    <Confirm
                        open={openConfirm}
                        message={`Confirma o envio dos leads selecionados para a sua API?`}
                        onClickNoButton={() => setOpenConfirm(false)}
                        onClickYesButton={handleClickYesButton}
                    />

                    <Modal open={cnpjView} size={"80%"}>
                        <Modal.Header closeButton onClose={() => setCnpjView(null)}></Modal.Header>
                        <CnpjConsultar cnpj={cnpjView} />
                    </Modal>
                </>
            }
        </Stack >
    )
}

export default LeadsEnvia