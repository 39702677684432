import { useEffect, useMemo, useState } from "react";
import { Button, Col, FlexboxGrid, Stack, Tooltip, Whisper } from "rsuite";

import SearchIcon from '@rsuite/icons/Search';

import DataTable from "../../components/DataTable";

import { dateFromString, formataCNPJ } from "../../services/utils";

import './style.css';

// Componente para renderizar a linha expandida
const RowSocio = ({ rowData }) => {
    return (
        <Stack spacing={30}>
            <div>Representante Legal</div>
            <Stack spacing={10}>
                <div>CPF:</div>
                <div>{rowData['cpf_representante_legal']}</div>
            </Stack>
            <Stack spacing={10}>
                <div>Nome:</div>
                <div>{rowData['nome_representante_legal']}</div>
            </Stack>
            <Stack spacing={10}>
                <div>Qualificação:</div>
                <div>{rowData['qualificacao_representante_legal']}</div>
            </Stack>
        </Stack>
    )
}

const ButtonOutrasEmpresas = ({ rowData, dataKey, handleViewSocioOutraEmpresa }) => {
    const texto = rowData[dataKey] === 1
        ? <div>É sócio em outra empresa.<br />Clique para visualizar qual é.</div>
        : <div>É sócio em outras {rowData[dataKey]} empresas.<br />Clique para visualizar quais são.</div>

    return (
        rowData[dataKey] > 0
            ? <Whisper placement="top" trigger="hover" speaker={<Tooltip>{texto}</Tooltip>}>
                <Button appearance="ghost" color="cyan" size="sm" style={{ padding: "4px 6px" }} onClick={() => handleViewSocioOutraEmpresa(rowData)}>
                    +{rowData[dataKey]}
                </Button>
            </Whisper>
            : <></>
    )
}

const DadosSocios = ({
    dados,
    setSocioOutraEmpresa,
    handleViewEmpresa
}) => {
    const [dataTableParams, setDataTableParams] = useState()
    const [socios, setSocios] = useState([])

    const handleViewSocioOutraEmpresa = socio => setSocioOutraEmpresa(socio)

    const dataTableColumns = {
        columns: [
            {
                dataKey: 'socio_em_outra_empresa',
                sortable: false,
                width: 50,
                style: { padding: '3px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' },
                customCellContent: (rowData, col) => (
                    <ButtonOutrasEmpresas
                        rowData={rowData}
                        dataKey={col.dataKey}
                        handleViewSocioOutraEmpresa={handleViewSocioOutraEmpresa}
                    />
                )
            }, {
                dataKey: 'nome_socio',
                headerCell: 'Nome',
                flexGrow: 2,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'qualificacao_socio',
                headerCell: 'Qualificação',
                minWidth: 200,
                flexGrow: 2,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'data_entrada_sociedade',
                headerCell: 'Data Entrada Sociedade',
                width: 180,
                sortable: true,
                customCellContent: (rowData, col) => {
                    const date = dateFromString(rowData[col.dataKey])
                    return date ? date.toLocaleDateString('pt-br') : ''
                }
            }, {
                dataKey: 'faixa_etaria',
                headerCell: 'Faixa Etária',
                width: 150,
                sortable: true
            }, {
                dataKey: 'identificador_de_socio',
                headerCell: 'Tipo',
                width: 150,
                sortable: true
            }, {
                dataKey: 'cnpj_cpf_do_socio',
                headerCell: 'CNPJ / CPF',
                width: 180,
                sortable: true,
                customCellContent: (rowData, col) => (
                    rowData[col.dataKey].length === 14
                        ? <Stack spacing={10}>
                            {formataCNPJ(rowData[col.dataKey])}
                            <Button appearance="ghost" color="cyan" size="sm" style={{ padding: "4px 6px" }} onClick={() => handleViewEmpresa(rowData[col.dataKey])}>
                                <SearchIcon />
                            </Button>
                        </Stack>
                        : rowData[col.dataKey]
                )
            }, {
                dataKey: 'pais',
                headerCell: 'País',
                width: 180,
                sortable: true,
                fullText: true
            }
        ]
    }

    const dadosSocios = useMemo(() => {
        return !dados.socios
            ? []
            : dados.socios.map((c, index) => ({
                id: index,
                nome_socio: c.nome_socio,
                qualificacao_socio: c.qualificacao_socio,
                faixa_etaria: c.faixa_etaria,
                data_entrada_sociedade: c.data_entrada_sociedade,
                cnpj_cpf_do_socio: c.cnpj_cpf_do_socio,
                identificador_de_socio: c.identificador_de_socio,
                pais: c.pais,
                cpf_representante_legal: c.cpf_representante_legal,
                nome_representante_legal: c.nome_representante_legal,
                qualificacao_representante_legal: c.qualificacao_representante_legal,
                socio_em_outra_empresa: c.socio_em_outra_empresa
            }))
    }, [dados.socios])

    const ordenador = (a, b) => {
        if (!dataTableParams || !a || !b) return 0

        const sortColumn = dataTableParams.sortColumn || 'data_entrada_sociedade'
        const sortType = dataTableParams.sortType || 'asc'

        if (sortType === 'asc') {
            if (a[sortColumn] > b[sortColumn]) return 1
            if (a[sortColumn] < b[sortColumn]) return -1
        } else {
            if (a[sortColumn] < b[sortColumn]) return 1
            if (a[sortColumn] > b[sortColumn]) return -1
        }

        return 0;
    }

    useEffect(() => {
        if (!dadosSocios.length || !dataTableParams) {
            setSocios({ data: [] })
        } else {
            const mapSocios = dadosSocios
                .map(c => c)
                .filter(c => (
                    c.nome_socio.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.qualificacao_socio.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.faixa_etaria.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.data_entrada_sociedade.includes(dataTableParams.searchText) ||
                    c.cnpj_cpf_do_socio.includes(dataTableParams.searchText) ||
                    c.identificador_de_socio.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.cpf_representante_legal.includes(dataTableParams.searchText) ||
                    c.nome_representante_legal.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.qualificacao_representante_legal.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.pais.toLowerCase().includes(dataTableParams.searchText.toLowerCase())
                ))
                .sort(ordenador)

            setSocios({
                data: mapSocios,
                total: dadosSocios.length,
                filtered: mapSocios.length
            })
        }
        // eslint-disable-next-line
    }, [dataTableParams, dadosSocios])

    const subTitle = socios.total > 0
        ? `${socios.total} Sócio${socios.total > 1 ? `s` : ''} ${socios.filtered !== socios.total ? `(exibindo ${socios.filtered})` : ''}`
        : dados.razao_social ? 'Dados não fornecidos pela Receita Federal'
            : ''

    return (
        <FlexboxGrid justify="start" align="bottom">
            <FlexboxGrid.Item as={Col} colspan={24}>
                <DataTable
                    hideBorder={true}
                    subtitle={subTitle}
                    data={socios}
                    dataTableColumns={dataTableColumns}
                    hiddeNewButton={true}
                    minHeight={514}
                    autoHeight={false}
                    onChangeParams={setDataTableParams}
                    rowKey={'id'}
                    expandedRowKeys={socios.data && socios.data.filter(c => c.nome_representante_legal).map(c => c.id)}
                    renderRowExpanded={rowData => <RowSocio rowData={rowData} />}
                    rowExpandedHeight={44}
                    rowClassName={'socios-table-row'}
                    hidePagination={true}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

export default DadosSocios