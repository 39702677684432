const enumNivelUsuario = {
    Usuario: 1,
    Admin: 3,
    Master: 5
}

export {
    enumNivelUsuario
}

