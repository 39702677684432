import { useContext } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { AuthContext } from '../contexts/authContext';
import { EmpresaContext } from '../contexts/empresaContext';

import Loading from '../components/Loading';
import Menu from '../components/Menu';
import PageNotFound from '../components/PageNotFound';
import Cliente from '../pages/Cliente';
import CnpjConsultar from '../pages/CnpjConsultar';
import Credito from '../pages/Credito';
import Dashboard from '../pages/Dashboard';
import Download from '../pages/Download';
import Empresa from '../pages/Empresa';
import Estatistica from '../pages/Estatistica';
import Extrato from '../pages/Extrato';
import LeadsConsultar from '../pages/LeadsConsultar';
import LeadsEnviar from '../pages/LeadsEnviar';
import Usuario from '../pages/Usuario';

import { enumNivelUsuario } from '../services/enum';

const PrivateRoute = () => {
    const { signed, loading } = useContext(AuthContext);

    if (loading) return <Loading />

    if (!signed) return <Navigate to='/login' replace={true} />

    return (
        <Menu>
            <Outlet />
        </Menu>
    )
}

const AllowedRoute = ({ nivel }) => {
    const { user } = useContext(AuthContext);

    if (user.nivel < nivel) return <Navigate to='/' replace={true} />

    return <Outlet />
}

const AllowedRouteSendLead = () => {
    const { user } = useContext(AuthContext);
    const { permiteTelaSelecaoLeads } = useContext(EmpresaContext)

    //Nivel 3 administrador
    if (user.nivel < enumNivelUsuario.Admin || !permiteTelaSelecaoLeads) return <Navigate to='/' replace={true} />

    return <Outlet />
}

const createPrivateRoutes = () => {
    return (
        <Route element={<PrivateRoute />}>
            <Route element={<AllowedRoute nivel={5} />}>
                <Route path='/download' element={<Download />} />
                <Route path='/empresa' element={<Empresa />} />
                <Route path='/credito' element={<Credito />} />
            </Route>

            <Route element={<AllowedRoute nivel={3} />}>
                <Route path='/usuario' element={<Usuario />} />
            </Route>

            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/cliente' element={<Cliente />} />
            <Route path='/extrato' element={<Extrato />} />
            <Route path='/leads' element={<LeadsConsultar />} />
            <Route path='/cnpj' element={<CnpjConsultar />} />
            <Route path='/estatistica' element={<Estatistica />} />

            <Route element={<AllowedRouteSendLead nivel={3} />}>
                <Route path='/envialeads' element={<LeadsEnviar />} />
            </Route>

            <Route path='/' element={<Dashboard />} />
            <Route path='*' element={<PageNotFound />} />
        </Route>
    )
}

export default createPrivateRoutes;