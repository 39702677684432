import { Message } from "rsuite";

export const showMessage = ({ toaster, successMessage, infoMessage, warningMessage, errorObject, executeFirst, executeLast, placement, duration }) => {
    if (!toaster) return false

    const timeDuration = duration || (errorObject ? 5000 : 2000)

    const message = errorObject
        ? (errorObject && errorObject.response && errorObject.response.data && errorObject.response.data.message)
            ? errorObject.response.data.message
            : errorObject.message
        : infoMessage || warningMessage || successMessage;

    const msgComponent = errorObject
        ? <Message type='error' showIcon closable header={message} />
        : infoMessage
            ? <Message type='info' showIcon closable header={message} />
            : warningMessage
                ? <Message type='warning' showIcon closable header={message} />
                : <Message type='success' showIcon closable header={message} />

    if (executeFirst) executeFirst();

    toaster.push(msgComponent, {
        placement: `${placement || 'topEnd'}`,
        duration: timeDuration
    });

    if (executeLast) setTimeout(() => executeLast(), duration);
}