import CurrencyInput from "react-currency-input-field"
import { Button, Checkbox, Col, DatePicker, FlexboxGrid, Input, InputGroup, Panel, Stack } from "rsuite"

import SelectSalvaFiltro from "../../components/SelectSalvaFiltro"
import VerticalTitle from "../../components/VerticalTitle"

import { useRef } from "react"
import './style.css'

const CustomInput = ({ filterFields, setFilterFields, fieldName, label, size }) => {
    return (
        <FlexboxGrid.Item
            as={Col}
            colspan={24}
            xl={size.xl || null}
            lg={size.lg || null}
            md={size.md || null}
            sm={size.sm || null}
            className="flex-box-grid-input"
        >
            <Stack spacing={3} direction="column" alignItems="flex-start">
                <label>{label}</label>
                <Stack.Item style={{ width: '100%' }}>
                    <Input
                        style={{ width: '100%' }}
                        value={filterFields[fieldName]}
                        onChange={value => setFilterFields({ ...filterFields, [fieldName]: value.toUpperCase() })}
                    />
                </Stack.Item>
            </Stack>
        </FlexboxGrid.Item>
    )
}

const CustomInputData = ({ filterFields, setFilterFields, fieldName, placeholder }) => {
    const handleChange = value => {
        //const date = value ? new Date(value.getFullYear(), value.getMonth(), value.getDate()) : null
        setFilterFields({ ...filterFields, [fieldName]: value })
    }

    return (
        <DatePicker
            className="dateInput"
            format="dd/MM/yyyy"
            placeholder={placeholder}
            oneTap
            value={filterFields[fieldName]}
            onChange={handleChange}
        />
    )
}

const CustomInputDataAbertura = ({ filterFields, setFilterFields }) => {
    return (
        <FlexboxGrid.Item as={Col} colspan={24} lg={11} xl={8} className="flex-box-grid-input">
            <Stack spacing={3} direction="column" alignItems="flex-start">
                <label>Data de abertura</label>
                <Stack.Item style={{ width: '100%' }}>
                    <InputGroup>
                        <InputGroup.Addon>de</InputGroup.Addon>
                        <CustomInputData filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'data_abertura_inicial'} placeholder={"data inicial"} />
                        <InputGroup.Addon>até</InputGroup.Addon>
                        <CustomInputData filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'data_abertura_final'} placeholder={"data final"} />
                    </InputGroup>
                </Stack.Item>
            </Stack>
        </FlexboxGrid.Item>
    )
}

const CustomInputCurrency = ({ filterFields, setFilterFields, fieldName }) => {
    return (
        <CurrencyInput
            className="currencyInput"
            decimalsLimit={2}
            decimalScale={2}
            allowNegativeValue={false}
            placeholder={'0,00'}
            value={filterFields[fieldName]}
            onValueChange={value => setFilterFields({ ...filterFields, [fieldName]: value })}
        />
    )
}

const CustomInputCapitalSocial = ({ filterFields, setFilterFields }) => {
    return (
        <FlexboxGrid.Item as={Col} colspan={24} lg={13} xl={9} className="flex-box-grid-input">
            <Stack spacing={3} direction="column" alignItems="flex-start">
                <label>Capital Social</label>
                <Stack.Item style={{ width: '100%' }}>
                    <InputGroup>
                        <InputGroup.Addon>de</InputGroup.Addon>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <CustomInputCurrency filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'capital_social_inicial'} />
                        <InputGroup.Addon>até</InputGroup.Addon>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <CustomInputCurrency filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'capital_social_final'} />
                    </InputGroup>
                </Stack.Item>
            </Stack>
        </FlexboxGrid.Item>
    )
}

const CustomHeader = (
    {
        totalSelecionado,
        filterGeral,
        filterJaEnviado,
        filterSelecionado,
        showOptions,
        setShowOptions,
        filterFields,
        setFilterFields,
        onClickApplyFilter,
        onClickClearFilter,
        onClickSendLeads,
        showButtonSendLeads
    }
) => {

    const pluralize = totalSelecionado ? 's' : ''
    const textSelecionado = `${totalSelecionado || 'Nenhum'} lead${pluralize} marcado${pluralize}`

    const handleClickClear = () => {
        if (salvaFiltroRef) salvaFiltroRef.current.clear()
        if (onClickClearFilter) onClickClearFilter()
    }

    const salvaFiltroRef = useRef()

    return (
        <Panel bordered className="leads-envia-custom-header">
            <VerticalTitle verticalTitle={"Filtros"}>
                <FlexboxGrid align="bottom">

                    <CustomInput size={{ xl: 8, lg: 13, md: 13 }} filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'razao_social'} label={'Razão Social'} />
                    <CustomInput size={{ xl: 5, lg: 9, md: 9 }} filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'municipio'} label={'Município'} />
                    <CustomInput size={{ xl: 2, lg: 2, md: 2 }} filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'uf'} label={'UF'} />

                    <CustomInputCapitalSocial filterFields={filterFields} setFilterFields={setFilterFields} />
                    <CustomInputDataAbertura filterFields={filterFields} setFilterFields={setFilterFields} />

                    <CustomInput size={{ xl: 2, lg: 3, md: 5, sm: 5 }} filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'codigo_cnae'} label={'Código CNAE'} />
                    <CustomInput size={{ xl: 14, lg: 21, md: 13, sm: 19 }} filterFields={filterFields} setFilterFields={setFilterFields} fieldName={'descricao_cnae'} label={'Descrição do CNAE'} />
                </FlexboxGrid >

                <Stack className="stack-footer" justifyContent="space-between">
                    <div className="checkbox-group">
                        <label>Exibir leads</label>
                        <Checkbox
                            checked={showOptions.exibir_marcado}
                            onChange={(_, checked) => setShowOptions({ ...showOptions, exibir_marcado: checked })}
                        >
                            Marcados ({filterSelecionado || 0})
                        </Checkbox>
                        <Checkbox
                            checked={showOptions.exibir_nao_marcado}
                            onChange={(_, checked) => setShowOptions({ ...showOptions, exibir_nao_marcado: checked })}
                        >
                            Não marcados ({((filterGeral || 0) - (filterSelecionado || 0) - (filterJaEnviado || 0)).toLocaleString('pt-br')})
                        </Checkbox>
                        <Checkbox
                            checked={showOptions.exibir_enviado}
                            onChange={(_, checked) => setShowOptions({ ...showOptions, exibir_enviado: checked })}
                        >
                            Já enviados ({filterJaEnviado || 0})
                        </Checkbox>
                    </div>
                    <div className="total-selected">{textSelecionado}</div>
                </Stack>
                <Stack className="stack-footer" justifyContent="space-between">
                    <Stack spacing={15} justifyContent="flex-end" >
                        <Button color="cyan" appearance="ghost" block onClick={onClickApplyFilter}>Aplicar filtros</Button>
                        <Button color="yellow" appearance="ghost" block onClick={handleClickClear}>Limpar filtros</Button>
                        <SelectSalvaFiltro
                            ref={salvaFiltroRef}
                            idTela={'ENVIARLEADS'}
                            getParametros={() => filterFields}
                            onSelectFilter={async params => {
                                const data_abertura_inicial = params.data_abertura_inicial ? new Date(params.data_abertura_inicial) : null
                                const data_abertura_final = params.data_abertura_final ? new Date(params.data_abertura_final) : null

                                setFilterFields({ ...filterFields, ...params, data_abertura_inicial, data_abertura_final })
                            }}
                        />
                    </Stack>
                    {showButtonSendLeads && totalSelecionado && <Button color="green" appearance="ghost" block onClick={onClickSendLeads}>Enviar {textSelecionado} para sua API</Button>}
                </Stack>
            </VerticalTitle>
        </Panel>
    )
}

export default CustomHeader