import { useContext, useEffect, useState } from "react";
import { Button, Col, FlexboxGrid, Form, MaskedInput, Message, Stack, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/ShowMessage";

import { AuthContext } from "../../contexts/authContext";
import { EmpresaContext } from "../../contexts/empresaContext";
import { useApiCliente } from "../../hooks/useApiCliente";
import { enumNivelUsuario } from "../../services/enum";
import { formataCNPJ, mask, notEmpty } from "../../services/utils";

const defaultValue = {
    idCliente: null,
    cnpj: '',
    cnpj_basico: '',
    razao_social: '',
    codigo_municipio: '',
    municipio: '',
    uf: '',
    codigo_cnae: '',
    descricao_cnae: '',
    integrado: false,
    data_referencia: null
}

const Cliente = () => {
    const { user } = useContext(AuthContext);
    const { empresaId } = useContext(EmpresaContext);

    const apiCliente = useApiCliente();

    const [clientes, setClientes] = useState({})
    const [cliente, setCliente] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [validCNPJ, setValidCNPJ] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);

    const getClientes = async () => {
        setLoading(true)
        if (!empresaId || !dataTableParams) {
            setClientes({});
        } else {
            try {
                const data = await apiCliente.getClientes({ ...dataTableParams, idEmpresa: empresaId })
                setClientes({
                    data: data.rows,
                    total: data.total,
                    filtered: data.filtered
                });
            } catch (error) {
                setClientes({});
                showError(error)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getClientes())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams])

    const getCliente = async id => {
        try {
            const data = await apiCliente.getCliente(id)
            setValidCNPJ(true)

            let date = null

            if (data.updatedAt && data.updatedAt !== '') date = new Date(data.updatedAt)
            else if (data.createdAt && data.createdAt !== '') date = new Date(data.createdAt)

            const data_referencia = date ? date.toLocaleDateString('pt-br') + ' as ' + date.toLocaleTimeString('pt-br', { timeStyle: 'short' }) : ''

            return {
                idEmpresa: data.idEmpresa,
                idCliente: data.idCliente,
                cnpj: data.cnpj,
                cnpj_basico: data.cnpj_basico,
                razao_social: data.razao_social,
                codigo_municipio: data.codigo_municipio,
                municipio: data.municipio,
                uf: data.uf,
                codigo_cnae: data.codigo_cnae,
                descricao_cnae: data.descricao_cnae,
                integrado: data.integrado,
                data_referencia: data_referencia
            }
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNewButton = () => {
        setCliente({
            ...defaultValue,
            idEmpresa: empresaId
        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEditButton = async id => {
        const data = await getCliente(id)
        if (data) {
            setCliente(data)
            setDeleteMode(false)
            setOpenEditModal(true)
        }
    }
    const handleClickDeleteButton = async id => {
        const data = await getCliente(id)
        if (data) {
            setCliente(data)
            setDeleteMode(true);
            setOpenEditModal(true);
        }
    }
    const handleClickSubmitButton = () => {
        if (validCNPJ) setOpenConfirm(true)
        else {
            showError({ message: "CNPJ Inválido" })
        }
    }
    const handleClickCancelButton = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await apiCliente.deleteCliente(cliente.idCliente) : await apiCliente.saveCliente(cliente)

            showSucess()
            handleClickCancelButton()
            await getClientes()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idCliente' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idCliente',
            onClick: handleClickEditButton
        },
        deleteButtonColumn: {
            dataKey: 'idCliente',
            onClick: handleClickDeleteButton
        },
        columns: [
            {
                dataKey: 'razao_social',
                headerCell: 'Razão Social',
                flexGrow: 2,
                minWidth: 250,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'cnpj',
                headerCell: 'CNPJ',
                width: 160,
                sortable: true,
                customCellContent: (rowData, col) => formataCNPJ(rowData[col.dataKey])
            }, {
                dataKey: 'cnpj_basico',
                headerCell: 'CNPJ Básico',
                width: 110,
                sortable: true,
                customCellContent: (rowData, col) => {
                    const cnpj = rowData[col.dataKey].replace(/\D/g, '');
                    if (cnpj.length === 8) return cnpj.replace(/(\d{2})(\d{3})(\d{3})/g, "$1.$2.$3");
                    return rowData[col.dataKey];
                }
            }, {
                dataKey: 'municipio',
                headerCell: 'Município',
                flexGrow: 1,
                minWidth: 150,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'uf',
                headerCell: 'UF',
                minWidth: 25,
                sortable: true
            }, {
                dataKey: 'codigo_cnae',
                headerCell: 'CNAE',
                minWidth: 30,
                sortable: true
            }
        ]
    }

    const [showErrorMessage, setShowErrorMessage] = useState({
        cnpj: false
    })

    const formValidation = {
        cnpj: {
            validation: (input) => notEmpty(input.value),
            message: 'CNPJ do cliente é obrigatório',
        }
    }

    // Compomente botão de Saneamento de cadastro
    // Declarei dentro o componente pai (Cliente) ´pois não será utilizado em outro componente
    // e a passagem de objetos/funções fica mais simples
    const [openConfirmSaneamento, setOpenConfirmSaneamento] = useState(false)

    const showInfoMessageSaneamento = () => showMessage({ toaster, infoMessage: `O processo foi iniciado. Por ser um processo demorado, você receberá o resultado no seu e-mail com um arquivo CSV anexo.`, placement: 'topCenter', duration: 10000 })
    const handleClickYesButtonSaneamento = () => {
        apiCliente.checkupCliente(empresaId)
        setOpenConfirmSaneamento(false)
        showInfoMessageSaneamento()
    }

    const ButtonSaneamentoCadastro = () => {
        const handleClick = () => {
            setOpenConfirmSaneamento(true)
        }

        return (
            user.nivel >= enumNivelUsuario.Master ?
                <Button onClick={handleClick} appearance='ghost' color='yellow' size='sm'>
                    Saneamento de Cadastro
                </Button>
                : <></>
        );
    }

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>Cadastro de Clientes</div>

            <DataTable
                data={clientes}
                dataTableColumns={dataTableColumns}
                placeholderSearch='id, razão social, cnpj, município, cnae'
                textNewButton='Novo Cliente'
                onClickNewButton={handleClickNewButton}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                ExtraButtons={<ButtonSaneamentoCadastro />}
            />

            <DataModal
                title='Cadastro de Cliente'
                open={openEditModal}
                sizeModal='lg'
                waiting={waiting}
                textSubmitButton={deleteMode ? 'Excluir' : 'Gravar'}
                colorSubmitButton={deleteMode ? 'red' : 'green'}
                onClickSubmitButton={handleClickSubmitButton}
                onClickCancelButton={handleClickCancelButton}
                hideSubmitButton={cliente.integrado}
                checkFormValidation={!deleteMode}
                formValidation={formValidation}
                setShowErrorMessage={setShowErrorMessage}
            >
                <FlexboxGrid className="flex-grid-form" align="top" justify="space-between">

                    <FlexboxGrid.Item as={Col} colspan={24} lg={6}>
                        <Form.Group controlId="idCliente">
                            <Form.ControlLabel>Código</Form.ControlLabel>
                            <Form.Control
                                name="idCliente"
                                readOnly
                                placeholder='gerado automaticamente'
                                value={cliente.idCliente || ''}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={10}>
                        <Form.Group controlId="cnpj">
                            <Form.ControlLabel>CNPJ</Form.ControlLabel>
                            <Form.Control
                                accepter={MaskedInput}
                                name="cnpj"
                                readOnly={deleteMode || cliente.integrado}
                                autoFocus={!cliente.integrado}
                                value={cliente.cnpj}
                                onChange={value => {
                                    const auxValue = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '').trim()
                                    if (!auxValue || auxValue.length < 14) {
                                        setValidCNPJ(false);
                                        setCliente({
                                            ...cliente,
                                            ...defaultValue,
                                            cnpj: auxValue
                                        });
                                    } else {
                                        (async () => {
                                            try {
                                                const data = await apiCliente.getDataRFB(auxValue);
                                                setValidCNPJ(true);
                                                setCliente({
                                                    ...cliente,
                                                    cnpj: auxValue,
                                                    cnpj_basico: data.cnpj_basico,
                                                    razao_social: data.razao_social,
                                                    codigo_municipio: data.codigo_municipio,
                                                    municipio: data.municipio,
                                                    uf: data.uf,
                                                    codigo_cnae: data.codigo_cnae,
                                                    descricao_cnae: data.descricao_cnae
                                                });
                                            }
                                            catch (error) {
                                                setCliente({
                                                    ...cliente,
                                                    ...defaultValue,
                                                    cnpj: auxValue
                                                });
                                                showError(error);
                                            }
                                        })();
                                    }
                                }}
                                mask={mask.cnpj}
                                placeholderChar={'\u2000'}
                                placeholder={'00.000.000/0000-00'}
                            />
                            <Form.ErrorMessage show={showErrorMessage.cnpj}>{formValidation.cnpj.message}</Form.ErrorMessage>
                            <Form.HelpText>Obrigatório</Form.HelpText>
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={8}>
                        <Form.Group controlId="cnpj_basico">
                            <Form.ControlLabel>CNPJ Básico</Form.ControlLabel>
                            <Form.Control
                                accepter={MaskedInput}
                                name="cnpj_basico"
                                placeholder="gerado através do cnpj"
                                readOnly
                                value={cliente.cnpj_basico}
                                mask={mask.cnpj_basico}
                                placeholderChar={'\u2000'}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                        <Form.Group controlId="razao_social">
                            <Form.ControlLabel>Razão Social</Form.ControlLabel>
                            <Form.Control
                                name="razao_social"
                                placeholder="importado da base de dados da Receita Federal"
                                readOnly
                                value={cliente.razao_social}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={18}>
                        <Form.Group controlId="municipio">
                            <Form.ControlLabel>Município</Form.ControlLabel>
                            <Form.Control
                                name="municipio"
                                placeholder="importado da base de dados da Receita Federal"
                                readOnly
                                value={cliente.municipio}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={6}>
                        <Form.Group controlId="uf">
                            <Form.ControlLabel>UF</Form.ControlLabel>
                            <Form.Control
                                name="uf"
                                placeholder="importado da base da RFB"
                                readOnly
                                value={cliente.uf}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                        <Form.Group controlId="cnae">
                            <Form.ControlLabel>CNAE</Form.ControlLabel>
                            <Form.Control
                                name="cnae"
                                placeholder="importado da base de dados da Receita Federal"
                                readOnly
                                value={`${cliente.codigo_cnae} - ${cliente.descricao_cnae}`}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                        {cliente.integrado
                            ? <Message type="info">Cliente incluído através da integração com o Cash-UP {cliente.data_referencia ? ' em ' + cliente.data_referencia : ''} </Message>
                            : cliente.idCliente
                                ? <Message type="success">Cliente cadastrado manualmente em {cliente.data_referencia ? ' em ' + cliente.data_referencia : ''} </Message>
                                : ''
                        }
                    </FlexboxGrid.Item>

                </FlexboxGrid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Cliente?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
            <Confirm
                open={openConfirmSaneamento}
                message={`Confirma a geração da planilha de saneamento de cadastro dos clientes dessa empresa?`}
                onClickNoButton={() => setOpenConfirmSaneamento(false)}
                onClickYesButton={handleClickYesButtonSaneamento}
            />
        </Stack >
    )
}

export default Cliente