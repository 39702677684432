import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button, Divider, Form, Loader, Modal, Stack } from 'rsuite';

import './style.css';

const DataModal = ({
    title,
    open,
    textSubmitButton,
    textCancelButton,
    colorSubmitButton,
    colorCancelButton,
    onClickSubmitButton,
    onClickCancelButton,
    hideSubmitButton,
    checkFormValidation,
    formValidation,
    setShowErrorMessage,
    waiting,
    sizeModal,
    children,
    bodyHeight,
    ...rest
}) => {

    const formRef = useRef()

    const handleSubmitButton = () => {
        if (checkFormValidation && formValidation) {
            const elements = formRef.current.root;
            let errors = {}
            let hasError = false
            for (let index = 0; index < elements.length; index++) {
                const element = elements[index];
                if (element.name !== '') {
                    const valid = formValidation[element.name]
                    if (valid) {
                        if (!valid.validation(element)) {
                            errors = { ...errors, [element.name]: true }
                            hasError = true
                        }
                    }
                }
            }
            setShowErrorMessage(errors)
            if (hasError) { return false }
        }
        onClickSubmitButton()
    }

    const handleCancelButton = () => {
        if (setShowErrorMessage) setShowErrorMessage({})
        onClickCancelButton()
    }

    return (
        <Modal
            overflow
            backdrop='static'
            size={sizeModal || 'md'}
            open={open}
            onClose={handleCancelButton}
            {...rest}
        >
            {title &&
                <>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Divider />
                </>
            }
            <Modal.Body className='data-modal-body' style={bodyHeight ? { height: bodyHeight } : null}>
                <Form fluid ref={formRef}>
                    {children}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Stack justifyContent='flex-end' className="data-modal-button-group">
                    {waiting
                        ? <Loader size="sm" content={<span style={{ fontSize: 16, fontWeight: 'bold' }}>Aguarde...</span>} />
                        : (
                            <>
                                {
                                    !hideSubmitButton &&
                                    <Button onClick={handleSubmitButton} appearance="ghost" color={colorSubmitButton || 'green'}>
                                        {textSubmitButton || 'Gravar'}
                                    </Button>
                                }
                                <Button onClick={handleCancelButton} appearance="ghost" color={colorCancelButton || 'orange'}>
                                    {textCancelButton || 'Cancelar'}
                                </Button>
                            </>
                        )
                    }
                </Stack>
            </Modal.Footer>
        </Modal >
    )
}

DataModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    textSubmitButton: PropTypes.string,
    textCancelButton: PropTypes.string,
    colorSubmitButton: PropTypes.string,
    colorCancelButton: PropTypes.string,
    onClickSubmitButton: PropTypes.func,
    onClickCancelButton: PropTypes.func,
    hideSubmitButton: PropTypes.bool,
    checkFormValidation: PropTypes.bool,
    formValidation: PropTypes.object,
    waiting: PropTypes.bool
}

export default DataModal
