import { useEffect, useMemo, useState } from "react"
import { Col, FlexboxGrid } from "rsuite"

import DataTable from "../../components/DataTable"

import DadosField from './DadosField'

const DadosCNAE = ({ dados }) => {

    const [dataTableParams, setDataTableParams] = useState()
    const [cnaes, setCnaes] = useState([])

    const dataTableColumns = {
        columns: [
            {
                dataKey: 'codigo',
                headerCell: 'CNAE',
                width: 80,
                sortable: true
            }, {
                dataKey: 'descricao',
                headerCell: 'Descrição',
                flexGrow: 2,
                sortable: true,
                fullText: true
            }
        ]
    }

    const dadosCnaes = useMemo(() => {
        return !dados.cnaes_secundarios
            ? []
            : dados.cnaes_secundarios.map(c => ({
                codigo: c.codigo,
                descricao: c.descricao
            }))
    }, [dados.cnaes_secundarios])

    const ordenador = (a, b) => {
        if (!dataTableParams || !a || !b) return 0

        const sortColumn = dataTableParams.sortColumn || 'codigo'
        const sortType = dataTableParams.sortType || 'asc'

        if (sortType === 'asc') {
            if (a[sortColumn] > b[sortColumn]) return 1
            if (a[sortColumn] < b[sortColumn]) return -1
        } else {
            if (a[sortColumn] < b[sortColumn]) return 1
            if (a[sortColumn] > b[sortColumn]) return -1
        }

        return 0;
    }

    useEffect(() => {
        if (!dadosCnaes.length || !dataTableParams) {
            setCnaes({ data: [] })
        } else {
            //  const posIni = (dataTableParams.pageNumber - 1) * dataTableParams.itemsPerPage
            const mapCnae = dadosCnaes
                .map(c => c)
                .filter(c => (
                    c.codigo.includes(dataTableParams.searchText) ||
                    c.descricao.toLowerCase().includes(dataTableParams.searchText.toLowerCase())
                ))
                .sort(ordenador)

            setCnaes({
                data: mapCnae,
                total: dadosCnaes.length,
                filtered: mapCnae.length
            })
        }
        // eslint-disable-next-line
    }, [dataTableParams, dadosCnaes])


    const subTitle = cnaes.total > 0
        ? `${cnaes.total} CNAE${cnaes.total > 1 ? `'s` : ''} Secundário${cnaes.total > 1 ? `s` : ''} ${cnaes.filtered !== cnaes.total ? `(exibindo ${cnaes.filtered})` : ''}`
        : dados.razao_social ? 'Nenhum CNAE Secundário encontrado'
            : 'CNAE Secundário'

    return (
        <FlexboxGrid justify="start" align="bottom">
            <DadosField label={'CNAE Principal'} value={dados.cnae_principal} style={{ marginBottom: 15 }} />
            <FlexboxGrid.Item as={Col} colspan={24}>
                <DataTable
                    subtitle={subTitle}
                    data={cnaes}
                    dataTableColumns={dataTableColumns}
                    hiddeNewButton={true}
                    minHeight={387}
                    autoHeight={false}
                    hidePagination={true}
                    onChangeParams={setDataTableParams}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

export default DadosCNAE