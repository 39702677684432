import { useEffect, useMemo, useState } from "react";
import { Button, Col, FlexboxGrid } from "rsuite";

import SearchIcon from '@rsuite/icons/Search';

import DataTable from "../../components/DataTable";

import { formataCNPJ } from "../../services/utils";

import './style.css';

const DadosEmpresasSocias = ({
    showMsg,
    dados,
    handleViewEmpresa
}) => {
    const [dataTableParams, setDataTableParams] = useState()
    const [empresas, setEmpresas] = useState([])

    const dataTableColumns = {
        columns: [
            {
                dataKey: 'cnpj',
                sortable: false,
                width: 36,
                style: { padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' },
                customCellContent: (rowData, col) => (
                    <Button appearance="ghost" color="cyan" size="sm" style={{ padding: "4px 6px" }} onClick={() => handleViewEmpresa(rowData[col.dataKey])}>
                        <SearchIcon />
                    </Button>
                )
            }, {
                dataKey: 'razao_social',
                headerCell: 'Razão Social',
                flexGrow: 2,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'capital_social',
                headerCell: 'Capital Social',
                width: 250,
                sortable: true,
                align: 'right',
                customCellContent: (rowData, col) => {
                    const valor = rowData[col.dataKey]
                    if (!valor) return ""
                    return valor.toLocaleString('pt-br', { style: "currency", currency: 'BRL' })
                }
            }, {
                dataKey: 'cnpj',
                headerCell: 'CNPJ',
                width: 200,
                sortable: true,
                customCellContent: (rowData, col) => formataCNPJ(rowData[col.dataKey])
            }
        ]
    }

    const dadosEmpresas = useMemo(() => {
        return !dados
            ? []
            : dados.map((c, index) => ({
                cnpj: c.cnpj,
                razao_social: c.razao_social,
                capital_social: c.capital_social
            }))
    }, [dados])

    const ordenador = (a, b) => {
        if (!dataTableParams || !a || !b) return 0

        const sortColumn = dataTableParams.sortColumn || 'cnpj'
        const sortType = dataTableParams.sortType || 'asc'

        if (sortType === 'asc') {
            if (a[sortColumn] > b[sortColumn]) return 1
            if (a[sortColumn] < b[sortColumn]) return -1
        } else {
            if (a[sortColumn] < b[sortColumn]) return 1
            if (a[sortColumn] > b[sortColumn]) return -1
        }

        return 0;
    }

    useEffect(() => {
        if (!dadosEmpresas.length || !dataTableParams) {
            setEmpresas({ data: [] })
        } else {
            const mapEmpresas = dadosEmpresas
                .map(c => c)
                .filter(c => (
                    c.razao_social.toLowerCase().includes(dataTableParams.searchText.toLowerCase())
                ))
                .sort(ordenador)

            setEmpresas({
                data: mapEmpresas,
                total: dadosEmpresas.length,
                filtered: mapEmpresas.length
            })
        }
        // eslint-disable-next-line
    }, [dataTableParams, dadosEmpresas])

    const subTitle = empresas.total > 0
        ? `É sócia em ${empresas.total} empresa${empresas.total > 1 ? `s` : ''} ${empresas.filtered !== empresas.total ? `(exibindo ${empresas.filtered})` : ''}`
        : showMsg ? 'Não é sócia em outras empresas'
            : ''

    return (
        <FlexboxGrid justify="start" align="bottom">
            <FlexboxGrid.Item as={Col} colspan={24}>
                <DataTable
                    hideBorder={true}
                    subtitle={subTitle}
                    data={empresas}
                    dataTableColumns={dataTableColumns}
                    hiddeNewButton={true}
                    minHeight={514}
                    autoHeight={false}
                    onChangeParams={setDataTableParams}
                    rowClassName={'socios-table-row'}
                    hidePagination={true}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

export default DadosEmpresasSocias