import {
  Avatar, Col, Container, Content, CustomProvider, Dropdown, Grid, Header, Nav, Navbar, Popover, Row, Sidebar,
  Sidenav, Stack, Toggle, Whisper
} from 'rsuite';

import ptBR from 'rsuite/locales/pt_BR';

import { forwardRef, useContext, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { Icon } from '@rsuite/icons';
import AdminIcon from '@rsuite/icons/Admin';
import NumbersIcon from '@rsuite/icons/Numbers';
import OffIcon from '@rsuite/icons/Off';
import PeoplesIcon from '@rsuite/icons/Peoples';
import PhoneIcon from '@rsuite/icons/Phone';
import SearchIcon from '@rsuite/icons/Search';
import SearchPeopleIcon from '@rsuite/icons/SearchPeople';

import { AiOutlineDashboard } from 'react-icons/ai';
import { BsCashCoin, BsCurrencyDollar, BsMoonStars } from 'react-icons/bs';
import { FaAngleLeft, FaAngleRight, FaRegAddressBook, FaWhatsapp } from 'react-icons/fa';
import { IoBusinessOutline, IoKeyOutline } from 'react-icons/io5';
import { VscDesktopDownload } from 'react-icons/vsc';

import AlterarSenha from '../../pages/AlterarSenha';
import PerfilUsuario from '../../pages/PerfilUsuario';
import SelectEmpresa from '../SelectEmpresa';

import { AuthContext } from '../../contexts/authContext';
import { EmpresaContext } from '../../contexts/empresaContext';
import { useApiExtrato } from '../../hooks/useApiExtrato';

import { enumNivelUsuario } from '../../services/enum';

import './style.css';

const MenuUsuario = forwardRef(({ user, onSelect, ...rest }, ref) => {
  const { toggleDarkMode, darkMode } = useContext(AuthContext)
  return (
    <Popover ref={ref}  {...rest}  >
      <Dropdown.Menu className='user-menu-container'>
        <Dropdown.Item panel style={{ padding: 10 }}>
          <h5 style={{ marginBottom: 5 }}>{user.nome}</h5>
          {user.cargo}
        </Dropdown.Item>
        <Dropdown.Separator />
        <Dropdown.Item panel className='user-menu-item panel'>
          <BsMoonStars />
          <strong>Modo escuro</strong>
          <Toggle checked={darkMode} onChange={toggleDarkMode} size="sm" />
        </Dropdown.Item>
        <Dropdown.Separator />
        <Dropdown.Item eventKey='perfil' onSelect={onSelect} className='user-menu-item' icon={<AdminIcon />}>Perfil</Dropdown.Item>
        <Dropdown.Item eventKey='senha' onSelect={onSelect} className='user-menu-item' icon={<IoKeyOutline />}>Alterar Senha</Dropdown.Item>
        <Dropdown.Separator />
        <Dropdown.Item eventKey='logout' onSelect={onSelect} className='user-menu-item' icon={<OffIcon />}>Sair</Dropdown.Item>
      </Dropdown.Menu >
    </Popover>
  )
});

const speakerSuporte = (
  <Popover>
    <Stack style={{ marginLeft: 7, fontSize: 13 }}
      alignItems='flex-start'
      justifyContent='center'
      direction='column'
      spacing={5}
    >
      <h5>Cash-UP Suporte</h5>
      atendimento@cashup.com.br
      <Stack
        direction='row'
        spacing={7}>
        <FaWhatsapp />
        (11) 94491-0606
      </Stack>
    </Stack>
  </Popover >
);

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="default" className={"nav-toggle" + (expand ? " expanded" : "")}>
      {expand &&
        <Nav className='nav-item-suporte'>
          <Whisper trigger="click" placement='top' speaker={speakerSuporte}>
            <Nav.Item className='link-menu-item'>
              <Stack style={{ marginLeft: 7 }}
                alignItems='flex-start'
                direction='row'
                spacing={20}
              >
                <PhoneIcon />
                Suporte
              </Stack>
            </Nav.Item>
          </Whisper>
        </Nav>
      }
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
          {expand ? <FaAngleLeft /> : <FaAngleRight />}
        </Nav.Item>
      </Nav>
    </Navbar>
  )
};

const Menu = () => {
  const { logout, user, darkMode } = useContext(AuthContext);
  const { empresaId, saldoCredito, empresaReadOnly, setEmpresaId, setPermiteTelaSelecaoLeads, setPermiteApiPropriaLeads, setSaldoCredito } = useContext(EmpresaContext);

  const apiExtrato = useApiExtrato();
  const navigate = useNavigate();
  const whisperRef = useRef();

  const [openPerfil, setOpenPerfil] = useState(false);
  const [openSenha, setOpenSenha] = useState(false);

  const [expand, setExpand] = useState(true);

  useQuery(["saldo-creditos", empresaId], () => {
    apiExtrato.getSaldo(empresaId)
      .then(data => {
        const saldo = parseInt(data.saldo)
        if (saldo !== saldoCredito) setSaldoCredito(saldo)
      })
  }, {
    refetchInterval: 5000
  })

  return (
    <CustomProvider theme={darkMode ? 'dark' : 'light'} locale={ptBR}>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <Sidebar className='sidebar-menu-container'
              width={expand ? 260 : 56}
              collapsible
            >
              <Sidenav.Header>
                <Stack className={'brand-container' + (expand ? " expanded" : "")} alignItems='center' justifyContent='center'>
                  <Link to='/'>
                    <img className='brand-logo' src={expand ? '/logo-cash-up-leads.png' : '/logo-cash-up-leads-icon.png'} alt='logo cashup-up leads' />
                  </Link>
                </Stack>
              </Sidenav.Header>
              <Sidenav className='menu-container' expanded={expand} appearance="default" >
                <Sidenav.Body className='menu-body'>
                  <Nav>
                    <Nav.Item className='menu-avatar-container' panel icon={expand ? null : <AdminIcon />}>
                      <Stack
                        style={{ padding: expand ? 15 : 30 }}
                        direction='column'
                        alignItems='center'
                        spacing={5}
                      >
                        <Avatar size="lg" circle src={user.foto} alt="usuario" />
                        {expand &&
                          <>
                            <div className='menu-avatar-username'>{user.nome}</div>
                            <div className='menu-avatar-office'>{user.cargo}</div>
                            {user.empresaNome && <div className='menu-avatar-company'>{user.empresaNome}</div>}
                          </>
                        }
                      </Stack>
                    </Nav.Item>
                    <Nav.Item as={Link} to='/dashboard' icon={<Icon as={AiOutlineDashboard} />}>
                      Dashboard
                    </Nav.Item>
                    {user.nivel >= enumNivelUsuario.Master &&
                      <Nav.Item as={Link} to='/empresa' icon={<Icon as={IoBusinessOutline} />}>
                        Empresa
                      </Nav.Item>
                    }
                    <Nav.Item as={Link} to='/cliente' icon={<Icon as={FaRegAddressBook} />}>
                      Cliente
                    </Nav.Item>
                    {user.nivel >= enumNivelUsuario.Master &&
                      <Nav.Item as={Link} to='/credito' icon={<Icon as={BsCashCoin} />}>
                        Crédito
                      </Nav.Item>
                    }
                    <Nav.Item as={Link} to='/extrato' icon={<Icon as={BsCurrencyDollar} />}>
                      Extrato
                    </Nav.Item>
                    {user.nivel >= enumNivelUsuario.Admin &&
                      <Nav.Item as={Link} to='/usuario' icon={<PeoplesIcon />}>
                        Usuários
                      </Nav.Item>
                    }
                    <Nav.Item as={Link} to='/leads' icon={<SearchPeopleIcon />}>
                      Consultar Leads
                    </Nav.Item>
                    <Nav.Item as={Link} to='/cnpj' icon={<SearchIcon />}>
                      Consultar CNPJ
                    </Nav.Item>
                    <Nav.Item as={Link} to='/estatistica' icon={<Icon as={NumbersIcon} />}>
                      Estatística
                    </Nav.Item>
                    {user.nivel >= enumNivelUsuario.Master &&
                      <Nav.Item as={Link} to='/download' icon={<Icon as={VscDesktopDownload} />}>
                        Atualização RFB
                      </Nav.Item>
                    }
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
              <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
            </Sidebar>
            <Container className={'page-container' + (!expand ? " expanded" : "")} >
              <Header>
                <Stack className='header-container' spacing={20} alignItems='flex-start'>
                  {user.nivel === enumNivelUsuario.Master &&
                    <div className='empresa-ativa-container'>
                      <SelectEmpresa
                        style={{ width: 450 }}
                        placeHolder='Selecione a empresa ativa'
                        readOnly={empresaReadOnly}
                        hiddePanel
                        cleanable={false}
                        captionValue={'Empresa ativa:'}
                        idEmpresaDefault={empresaId}
                        onSelectEmpresa={empresa => {
                          setEmpresaId(empresa.idEmpresa)
                          setPermiteTelaSelecaoLeads(empresa.permiteTelaSelecaoLeads)
                          setPermiteApiPropriaLeads(empresa.permiteApiPropriaLeads)
                        }}
                      />
                    </div>
                  }
                  <div className='saldo-creditos-container'>
                    <Icon as={BsCashCoin} />
                    {`$ ${saldoCredito.toLocaleString('pt-BR')}`}
                  </div>
                  <Whisper
                    ref={whisperRef}
                    trigger="click"
                    placement='bottomEnd'
                    speaker={
                      <MenuUsuario
                        user={user}
                        onSelect={(eventKey, event) => {
                          whisperRef.current.close()
                          switch (eventKey) {
                            case 'perfil':
                              setOpenPerfil(true);
                              break;
                            case 'senha':
                              setOpenSenha(true);
                              break;
                            case 'logout':
                              logout()
                              navigate('/login', { replace: true })
                              break;
                            default:
                              break;
                          }
                        }}
                      />
                    }
                  >
                    <Avatar size="md" circle src={user.foto} alt="usuario" className='menu-usuario' />
                  </Whisper>
                </Stack>
              </Header>
              <Content className='page-content'>
                <Outlet />
                <PerfilUsuario open={openPerfil} setOpen={setOpenPerfil} />
                <AlterarSenha open={openSenha} setOpen={setOpenSenha} />
              </Content>
            </Container>
          </Col>
        </Row>
      </Grid>
    </CustomProvider >
  );
}

export default Menu;