import { Icon } from '@rsuite/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { Button, Drawer, Input, Loader, Stack, Text } from 'rsuite';

const Confirm = ({
    open,
    operation,
    validationValue,
    waiting,
    onClickConfirmButton,
    onClickCancelButton
}) => {

    const [inputValidation, setInputValidation] = useState('')

    return (
        <Drawer backdrop='static' size='xs' placement='bottom' open={open} style={{ height: 120 }}>
            <Drawer.Header closeButton={false} style={{ border: 'unset', justifyContent: 'space-between' }}>
                <Drawer.Title style={{ flexGrow: 'unset' }}>
                    <Stack spacing={20}>
                        <Icon as={BsExclamationCircle} size='2em' color='orange' />
                        <Stack spacing={7} direction='row'>
                            {`Digite exatamente`}
                            <Text color='red'>{validationValue}</Text>
                            {`para confirmar a ${operation}:`}
                        </Stack>
                        <Input
                            value={inputValidation}
                            onChange={setInputValidation}
                        />
                    </Stack>
                </Drawer.Title>
                {waiting
                    ? <Loader size="sm" content={<span style={{ fontSize: 16, fontWeight: 'bold' }}>Aguarde...</span>} />
                    : (
                        <Drawer.Actions>
                            <Button
                                onClick={() => {
                                    const validated = (inputValidation === validationValue)
                                    onClickConfirmButton(validated)
                                    setInputValidation('')
                                }}
                                appearance="ghost"
                                color='green'
                            >
                                Confirmar
                            </Button>
                            <Button
                                onClick={() => {
                                    onClickCancelButton()
                                    setInputValidation('')
                                }}
                                appearance="ghost"
                                color='orange'
                            >
                                Cancelar
                            </Button>
                        </Drawer.Actions>
                    )
                }
            </Drawer.Header>
        </Drawer>
    )
}

Confirm.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    waiting: PropTypes.bool,
    onClickYesButton: PropTypes.func,
    onClickNoButton: PropTypes.func,
}

export default Confirm
