import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, Container, Content, CustomProvider, Footer, Form, InputGroup, Loader, Panel, Stack, Text, useToaster } from "rsuite";
import { ptBR } from "rsuite/esm/locales";

import { showMessage } from "../../components/ShowMessage";
import { AuthContext } from "../../contexts/authContext";

import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

import './style.css';

const Login = () => {
    const { login } = useContext(AuthContext);

    const navigate = useNavigate();
    const toaster = useToaster();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    const handleLogin = async () => {
        if (loading) return false
        setLoading(true)

        const result = await login(email, password);

        if (result) {
            toaster.clear();
            navigate('/', { replace: true, state: null });
        } else {
            showMessage({ toaster, errorObject: { message: 'E-mail ou senha incorreto.' }, placement: 'topCenter' })
        }

        setLoading(false)
    }

    const handleVisible = () => setVisible(!visible);

    const anoAtual = (new Date()).getFullYear();

    return (
        <CustomProvider theme='light' locale={ptBR}>
            <Container className="container-login">
                <Content>
                    <Panel bordered shaded className="data-panel">

                        <div className="container-logo-login" >
                            <img alt="logo" src='/logo-cash-up-leads.png' />
                        </div>

                        <Form fluid>
                            <Form.Group controlId="email">
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control
                                    autoFocus
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={setEmail}
                                />
                            </Form.Group>

                            <Form.Group controlId="password">
                                <Form.ControlLabel>Senha</Form.ControlLabel>
                                <InputGroup inside>
                                    <Form.Control
                                        name="password"
                                        autoComplete="off"
                                        type={visible ? 'text' : 'password'}
                                        value={password}
                                        onChange={setPassword}
                                    />
                                    <InputGroup.Button onClick={handleVisible}>
                                        {visible ? <EyeIcon /> : <EyeSlashIcon />}
                                    </InputGroup.Button>
                                </InputGroup>
                            </Form.Group>

                            <Stack justifyContent="space-between" alignItems="flex-end">
                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button style={{ height: 40 }} appearance={loading ? "primary" : "ghost"} color={loading ? "yellow" : "green"} onClick={handleLogin}>
                                            {loading
                                                ?
                                                <Stack spacing={10} alignItems="center" justifyContent="center">
                                                    <Loader />
                                                    <div style={{ fontSize: 16 }}>Aguarde...</div>
                                                </Stack>
                                                : 'Login'}
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>

                                <Link to='/recuperasenha'>Esqueceu a senha?</Link>
                            </Stack>

                        </Form>
                    </Panel>
                </Content>
                <Footer className="footer-login">
                    <Text size="lg" color="blue">
                        <a href="https://www.cashup.com.br/" target="_blank" rel="noreferrer">
                            © {anoAtual}, Cash-UP Tecnologia para o Aumento das Vendas
                        </a>
                    </Text>
                </Footer>
            </Container>
        </CustomProvider >
    )
}

export default Login
