import { useContext, useEffect, useState } from 'react';
import { Checkbox, Col, Divider, FlexboxGrid, Form, InputGroup, InputNumber, Panel, Stack, Text, Tooltip, Whisper, useToaster } from 'rsuite';

import { useApiEmpresa } from '../../hooks/useApiEmpresa';

import { EmpresaContext } from "../../contexts/empresaContext";

import Confirm from '../../components/Confirm';
import ConfirmValidation from '../../components/ConfirmValidation';
import DataModal from '../../components/DataModal';
import DataTable from '../../components/DataTable';
import { showMessage } from '../../components/ShowMessage';

import { notEmpty } from '../../services/utils';

import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

import './style.css';

const Empresa = () => {
    const { empresaId, setPermiteApiPropriaLeads } = useContext(EmpresaContext)

    const apiEmpresa = useApiEmpresa();

    const [empresas, setEmpresas] = useState({});
    const [empresa, setEmpresa] = useState({});

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [showPassword, setShowPassword] = useState({
        integracao: false,
        api: false,
    });

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openValidation, setOpenValidation] = useState(false)

    const [deleteMode, setDeleteMode] = useState(false)

    const getEmpresas = async () => {
        setLoading(true)
        if (dataTableParams) {
            try {
                const data = await apiEmpresa.getEmpresas(dataTableParams)
                setEmpresas({
                    data: data.rows,
                    total: data.total,
                    filtered: data.filtered
                });
            } catch (error) {
                setEmpresas({});
                showError(error)
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => await getEmpresas())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const getEmpresa = async id => {
        try {
            const data = await apiEmpresa.getEmpresa(id)
            return {
                idEmpresa: data.idEmpresa,
                nomeEmpresa: data.nomeEmpresa,
                limiteUsuario: data.limiteUsuario,
                usuarioIntegracao: data.usuarioIntegracao,
                senhaIntegracao: data.senhaIntegracao,
                ipIntegracao: data.ipIntegracao,
                usuarioApi: data.usuarioApi,
                senhaApi: data.senhaApi,
                ipApi: data.ipApi,
                permiteTelaSelecaoLeads: data.permiteTelaSelecaoLeads,
                permiteApiCNPJ: data.permiteApiCNPJ,
                permiteApiLeads: data.permiteApiLeads,
                permiteApiPropriaLeads: data.permiteApiPropriaLeads,
                permiteApiGoogleLocalizacao: data.permiteApiGoogleLocalizacao
            }
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setEmpresa({
            idEmpresa: null,
            nomeEmpresa: '',
            limiteUsuario: 0,
            permiteTelaSelecaoLeads: false,
            permiteApiCNPJ: false,
            permiteApiLeads: false,
            permiteApiPropriaLeads: false,
            permiteApiGoogleLocalizacao: false
        })
        setDeleteMode(false)
        setOpenEditModal(true);
    }
    const handleClickEdit = async id => {
        const data = await getEmpresa(id)
        if (data) {
            setEmpresa(data)
            setDeleteMode(false);
            setOpenEditModal(true);
        }
    }
    const handleClickDelete = async id => {
        const data = await getEmpresa(id)
        if (data) {
            setEmpresa(data)
            setDeleteMode(true);
            setOpenEditModal(true);
        }
    }

    const handleClickCancel = () => {
        setOpenEditModal(false);
        setWaiting(false);
        setShowPassword({
            integracao: false,
            api: false
        });
    }

    const handleClickYes = async () => {
        setOpenConfirm(false)

        if (deleteMode) {
            setOpenValidation(true)
        } else {
            setWaiting(true)
            try {
                const response = await apiEmpresa.saveEmpresa(empresa)

                if (empresa.idEmpresa === empresaId) setPermiteApiPropriaLeads(empresa.permiteApiPropriaLeads)

                showSucess()
                handleClickCancel()
                await getEmpresas()

                if (response.status === 201) {
                    if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                        (dataTableParams.sortColumn === 'idEmpresa' && dataTableParams.sortType === 'asc')) {
                        setGoToLastPage(true)
                    }
                }
            } catch (error) {
                showError(error)
            }
        }
    }

    const handleClickConfirmValidation = async validated => {

        setOpenValidation(false)

        if (!validated) {
            showError({ message: 'Palavra de validação não confere, exclusão cancelada.' })
            return false
        }

        setWaiting(true)
        try {
            await apiEmpresa.deleteEmpresa(empresa.idEmpresa)

            showSucess()
            handleClickCancel()
            await getEmpresas()

        } catch (error) {
            showError(error)
        }
    }

    const handleVisible = field => {
        const visible = showPassword[field]
        setShowPassword({ ...showPassword, [field]: !visible })
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idEmpresa',
            onClick: handleClickEdit
        },
        deleteButtonColumn: {
            dataKey: 'idEmpresa',
            onClick: handleClickDelete,
            hidden: rowData => rowData['idEmpresa'] === 1
        },
        columns: [
            {
                dataKey: 'idEmpresa',
                headerCell: 'Cód',
                headerStyle: { alignItems: 'center' },
                width: 70,
                align: 'center',
                sortable: true
            }, {
                dataKey: 'nomeEmpresa',
                headerCell: 'Nome',
                headerStyle: { alignItems: 'center' },
                flexGrow: 1,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'permiteTelaSelecaoLeads',
                headerCell: 'Pode usar tela de seleção de Leads',
                headerStyle: { whiteSpace: 'break-spaces', alignItems: 'center', textAlign: 'center' },
                align: 'center',
                sortable: false,
                customCellContent: (rowData, col) => rowData['permiteTelaSelecaoLeads'] ? "Sim" : ""
            }, {
                dataKey: 'saldoCreditos',
                headerCell: 'Créditos',
                headerStyle: { alignItems: 'center' },
                align: 'right',
                sortable: true,
                customCellContent: (rowData, col) => parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')
            }, {
                columnGroup: 'Usuários',
                columns: [
                    {
                        dataKey: 'limiteUsuario',
                        headerCell: 'Limite',
                        width: 100,
                        align: 'center',
                        sortable: true
                    }, {
                        dataKey: 'usuariosAtivos',
                        headerCell: 'Ativos',
                        width: 100,
                        align: 'center',
                        sortable: true
                    }, {
                        dataKey: 'usuariosInativos',
                        headerCell: 'Inativos',
                        width: 100,
                        align: 'center',
                        sortable: true
                    }
                ]
            }
        ]
    }

    const [showErrorMessage, setShowErrorMessage] = useState({
        nomeEmpresa: false
    })

    const formValidation = {
        nomeEmpresa: {
            validation: (input) => notEmpty(input.value),
            message: 'Nome da empresa é obrigatório',
        }
    }

    const textToolTipoIPServidor = `Se não informar o campo, qualquer IP poderá consumir a API, caso informe, o sistema irá restringir
                                    o acesso ao(s) endereço(s) informado(s). Para informar vários endereços, separe-os por ponto e virgula(;).`

    return (
        <>
            <DataTable
                data={empresas}
                dataTableColumns={dataTableColumns}
                title='Cadastro de Empresas'
                placeholderSearch='id ou nome da empresa'
                textNewButton='Nova Empresa'
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
            />

            <DataModal
                title={'Cadastro de Empresa'}
                open={openEditModal}
                waiting={waiting}
                textSubmitButton={deleteMode ? 'Excluir' : 'Gravar'}
                colorSubmitButton={deleteMode ? 'red' : 'green'}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                checkFormValidation={!deleteMode}
                formValidation={formValidation}
                setShowErrorMessage={setShowErrorMessage}
            >
                <FlexboxGrid className="flex-grid-form" align="top" justify="space-between">

                    <FlexboxGrid.Item as={Col} colspan={24} lg={6}>
                        <Form.Group controlId="idEmpresa">
                            <Form.ControlLabel>Código</Form.ControlLabel>
                            <Form.Control
                                name="idEmpresa"
                                readOnly
                                placeholder='gerado automaticamente'
                                value={empresa.idEmpresa || ''}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={20}>
                        <Form.Group controlId="nomeEmpresa">
                            <Form.ControlLabel>Nome da Empresa</Form.ControlLabel>
                            <Form.Control
                                name="nomeEmpresa"
                                readOnly={deleteMode || empresa.idEmpresa === 1}
                                autoFocus={!deleteMode && empresa.idEmpresa !== 1}
                                value={empresa.nomeEmpresa}
                                onChange={value => setEmpresa({ ...empresa, nomeEmpresa: value.toUpperCase() })}
                            />
                            <Form.ErrorMessage show={showErrorMessage.nomeEmpresa}>{formValidation.nomeEmpresa.message}</Form.ErrorMessage>
                            <Form.HelpText>Obrigatório</Form.HelpText>
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={4}>
                        <Form.Group controlId='limiteUsuario'>
                            <Form.ControlLabel>Limite de Usuários</Form.ControlLabel>
                            <Form.Control
                                name="limiteUsuario"
                                readOnly={deleteMode}
                                accepter={InputNumber}
                                min={0}
                                value={empresa.limiteUsuario}
                                onChange={value => setEmpresa({ ...empresa, limiteUsuario: value })}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={24} className='no-margin-bottom'>
                        <Form.Group controlId='permiteTelaSelecaoLeads'>
                            <Form.Control
                                name="permiteTelaSelecaoLeads"
                                readOnly={deleteMode}
                                accepter={Checkbox}
                                inline
                                checked={empresa.permiteTelaSelecaoLeads}
                                onChange={(_, checked) => {
                                    if (checked) {
                                        setEmpresa({ ...empresa, permiteTelaSelecaoLeads: checked })
                                    } else {
                                        setEmpresa({ ...empresa, permiteTelaSelecaoLeads: checked, permiteApiLeads: checked })
                                    }
                                }}
                            >
                                Permitir uso da tela de seleção de Leads para envio para API prória ou busca usando nossa API
                            </Form.Control>
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item as={Col} colspan={24} lg={24} className='no-margin-bottom' style={{ marginLeft: 30 }}>
                        <Form.Group controlId='permiteApiLeads'>
                            <Form.Control
                                name="permiteApiLeads"
                                readOnly={deleteMode}
                                accepter={Checkbox}
                                inline
                                checked={empresa.permiteApiLeads}
                                disabled={!empresa.permiteTelaSelecaoLeads}
                                onChange={(_, checked) => {
                                    if (checked) setEmpresa({ ...empresa, permiteApiLeads: checked, permiteApiPropriaLeads: false })
                                }}
                            >
                                Permitir uso da nossa API para busca dos Leads selecionados
                            </Form.Control>
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item as={Col} colspan={24} lg={24} className='no-margin-bottom' style={{ marginLeft: 30 }}>
                        <Form.Group controlId='permiteApiPropriaLeads'>
                            <Form.Control
                                name="permiteApiPropriaLeads"
                                readOnly={deleteMode}
                                accepter={Checkbox}
                                inline
                                checked={empresa.permiteApiPropriaLeads}
                                disabled={!empresa.permiteTelaSelecaoLeads}
                                onChange={(_, checked) => {
                                    if (checked) setEmpresa({ ...empresa, permiteApiPropriaLeads: checked, permiteApiLeads: false })
                                }}
                            >
                                Permitir envio dos Leads selecionados para API própria (terceiro)
                            </Form.Control>
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item as={Col} colspan={24} lg={24} className='no-margin-bottom'>
                        <Form.Group controlId='permiteApiCNPJ'>
                            <Form.Control
                                name="permiteApiCNPJ"
                                readOnly={deleteMode}
                                accepter={Checkbox}
                                inline
                                checked={empresa.permiteApiCNPJ}
                                onChange={(_, checked) => setEmpresa({ ...empresa, permiteApiCNPJ: checked })}
                            >
                                Permitir uso da nossa API para consulta de CNPJ
                            </Form.Control>
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                        <Form.Group controlId='permiteApiGoogleLocalizacao'>
                            <Form.Control
                                name="permiteApiGoogleLocalizacao"
                                readOnly={deleteMode}
                                accepter={Checkbox}
                                inline
                                checked={empresa.permiteApiGoogleLocalizacao}
                                onChange={(_, checked) => setEmpresa({ ...empresa, permiteApiGoogleLocalizacao: checked })}
                            >
                                Permitir uso do nosso serviço de API do Google para pesquisa da Geolocalização do Lead
                            </Form.Control>
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} >
                        <Panel header="Configuração de Segurança para uso das API's disponibilizadas pelo Cash-UP Leads" bordered className='panel-security-api'>

                            <Divider className='divider-security-api'>Para uso das APIs pelo sistema Cash-UP (integração)</Divider>

                            <FlexboxGrid.Item as={Col} colspan={12} lg={7}>
                                <Form.Group controlId='usuarioIntegracao'>
                                    <Form.ControlLabel>Usuário</Form.ControlLabel>
                                    <Form.Control
                                        name="usuarioIntegracao"
                                        autoComplete="off"
                                        readOnly={deleteMode}
                                        value={empresa.usuarioIntegracao}
                                        onChange={value => setEmpresa({ ...empresa, usuarioIntegracao: value })}
                                    />
                                </Form.Group>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item as={Col} colspan={12} lg={7}>
                                <Form.Group controlId='senhaIntegracao'>
                                    <Form.ControlLabel>Senha</Form.ControlLabel>
                                    <InputGroup inside>
                                        <Form.Control
                                            name="senhaIntegracao"
                                            autoComplete="off"
                                            type={showPassword.integracao ? 'text' : 'password'}
                                            readOnly={deleteMode}
                                            value={empresa.senhaIntegracao}
                                            onChange={value => setEmpresa({ ...empresa, senhaIntegracao: value })}
                                        />
                                        <InputGroup.Button onClick={() => handleVisible('integracao')}>
                                            {showPassword.integracao ? <EyeIcon /> : <EyeSlashIcon />}
                                        </InputGroup.Button>
                                    </InputGroup>
                                </Form.Group>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item as={Col} colspan={24} lg={10}>
                                <Form.Group controlId='ipIntegracao'>
                                    <Form.ControlLabel>
                                        <Stack spacing={10}>
                                            IP do Servidor
                                            <Whisper placement="top" speaker={<Tooltip>{textToolTipoIPServidor}</Tooltip>}>
                                                <div className="header-help">?</div>
                                            </Whisper>
                                        </Stack>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        name="ipIntegracao"
                                        readOnly={deleteMode}
                                        value={empresa.ipIntegracao}
                                        onChange={value => setEmpresa({ ...empresa, ipIntegracao: value })}
                                    />
                                </Form.Group>
                            </FlexboxGrid.Item>

                            <Divider className='divider-security-api'>Para uso das APIs por sistemas de terceiros</Divider>

                            <FlexboxGrid.Item as={Col} colspan={12} lg={7}>
                                <Form.Group controlId='usuarioApi'>
                                    <Form.ControlLabel>Usuário</Form.ControlLabel>
                                    <Form.Control
                                        name="usuarioApi"
                                        autoComplete="off"
                                        readOnly={deleteMode}
                                        value={empresa.usuarioApi}
                                        onChange={value => setEmpresa({ ...empresa, usuarioApi: value })}
                                    />
                                </Form.Group>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item as={Col} colspan={12} lg={7}>
                                <Form.Group controlId='senhaApi'>
                                    <Form.ControlLabel>Senha</Form.ControlLabel>
                                    <InputGroup inside>
                                        <Form.Control
                                            name="senhaApi"
                                            autoComplete="off"
                                            type={showPassword.api ? 'text' : 'password'}
                                            readOnly={deleteMode}
                                            value={empresa.senhaApi}
                                            onChange={value => setEmpresa({ ...empresa, senhaApi: value })}
                                        />
                                        <InputGroup.Button onClick={() => handleVisible('api')}>
                                            {showPassword.api ? <EyeIcon /> : <EyeSlashIcon />}
                                        </InputGroup.Button>
                                    </InputGroup>
                                </Form.Group>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item as={Col} colspan={24} lg={10}>
                                <Form.Group controlId='ipApi'>
                                    <Form.ControlLabel>
                                        <Stack spacing={10}>
                                            IP do Servidor
                                            <Whisper placement="top" speaker={<Tooltip>{textToolTipoIPServidor}</Tooltip>}>
                                                <div className="header-help">?</div>
                                            </Whisper>
                                        </Stack>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        name="ipApi"
                                        readOnly={deleteMode}
                                        value={empresa.ipApi}
                                        onChange={value => setEmpresa({ ...empresa, ipApi: value })}
                                    />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </Panel>
                    </FlexboxGrid.Item>

                </FlexboxGrid>
            </DataModal>

            <Confirm
                open={openConfirm}
                message={
                    deleteMode
                        ? <Stack direction='column' alignItems='start'>
                            <Text>Confirma a exclusão dos dados da Empresa e de todos os dados relacionados?</Text>
                            <Text color='red'>Atenção! Após a confirmação esta operação não poderá ser desfeita!</Text>
                        </Stack>
                        : 'Confirma a gravação dos dados da Empresa?'
                }
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYes}
            />

            <ConfirmValidation
                open={openValidation}
                operation='exclusão'
                validationValue={empresa.nomeEmpresa}
                onClickCancelButton={() => setOpenValidation(false)}
                onClickConfirmButton={handleClickConfirmValidation}
            />
        </>
    )
}

export default Empresa
