import api from '../services/api.js';

const getMunicipios = async (uf, { start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get(`/municipios/${uf}`, { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getCnaes = async ({ start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get('/cnaes', { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getQualiicacao = async ({ start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get('/qualificacao', { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getUFs = async ({ start, pageNumber, itemsPerPage, searchText, arrayCodigos }) => {
    const response = await api.get('/ufs', { params: { start, pageNumber, itemsPerPage, searchText, arrayCodigos } });
    return response.data;
}

const getFormaTributacao = async () => {
    const response = await api.get('/formatributacao');
    return response.data;
}

export const useApiRFBTabelas = () => ({
    getMunicipios,
    getCnaes,
    getUFs,
    getQualiicacao,
    getFormaTributacao
})